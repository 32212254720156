<template>
	<Head>
		<link
			rel="preload"
			as="font"
		/>
		<link
			rel="preconnect"
			href="https://fonts.googleapis.com"
		/>
		<link
			rel="preconnect"
			href="https://fonts.gstatic.com"
			crossorigin
		/>
		<link
			href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap"
			rel="stylesheet"
		/>
	</Head>

	<slot />

	<Footer />
</template>

<script setup>
const { gtm_id } = useRuntimeConfig().public;

useHead({
	bodyAttrs: {
		class: "full-width-content"
	},
	link: [
		{ rel: "icon", type: "image/x-icon", href: "/favicon.ico" },
		{ rel: "manifest", href: "/site.webmanifest" },
		{
			rel: "icon",
			type: "image/png",
			sizes: "16x16",
			href: "/fav-16x16.png"
		},
		{
			rel: "icon",
			type: "image/png",
			sizes: "32x32",
			href: "/fav-32x32.png"
		},
		{ rel: "mask-icon", color: "#5BBAD5", href: "/safari-pinned-tab.svg" },
		{ rel: "icon", type: "apple-touch-icon", href: "/apple-touch-icon.png" }
	],
	script: [
		{
			async: true,
			src: `https://www.googletagmanager.com/gtm.js?id=${gtm_id}`,
			body: true
		}
	]
});

onMounted(() => {
	if (process.client) {
		const script = document.createElement("script");

		script.setAttribute(
			"src",
			"https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js"
		);
		script.setAttribute("async", true);
		document.body.appendChild(script);

		const script2 = document.createElement("script");

		script2.innerHTML = `
      window.OneSignalDeferred = window.OneSignalDeferred || [];
      OneSignalDeferred.push(function(OneSignal) {
        OneSignal.init({
          appId: "6e0641b9-91d3-4781-8c85-33e8776eb7b3",
          safari_web_id: "web.onesignal.auto.2fc72fe0-a0df-475b-ad9a-b2dac840a493",
          notifyButton: {
            enable: true,
          },
          allowLocalhostAsSecureOrigin: true,
        });
      });
    `;
		document.body.appendChild(script2);
	}
});
</script>

<style lang="scss">
.swiper-slide p {
	font-size: 15.2px;
}

.swiper-slide-button.prev {
	left: -36px;
	top: -52px;
}

.swiper-slide-button.next {
	right: -36px;
	top: -52px;
}

.testimonial-text .testimonial-author {
	font-size: 20px;
}

.testimonial-text h5 {
	font-size: 10.5px;
}

@media (min-width: 768px) {
	.swiper-slide p {
		font-size: 19px;
	}

	.swiper-slide-button.prev {
		left: 0;
		top: -32px;
	}

	.swiper-slide-button.next {
		right: 0;
		top: -32px;
	}
}
</style>
